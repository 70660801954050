var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container mb-2"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Năm","label-for":"year"}},[_c('v-select',{attrs:{"options":_vm.dataFilterYear || [],"placeholder":"Năm"},on:{"input":_vm.getListUser},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.queryParams.year),callback:function ($$v) {_vm.$set(_vm.queryParams, "year", $$v)},expression:"queryParams.year"}})],1)],1)],1)],1),_c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm người lao động',"showBtnMultiDelete":_vm.showBtnMultiDelete,"arrayExcel":_vm.arrayExcel},on:{"clickDowloadSample":_vm.downloadExampleFile,"clickExportExcel":_vm.downloadExportFile,"importFile":function($event){return _vm.importFileExcel($event)},"clickDelete":_vm.deleteItems,"clickAdd":_vm.addUser,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.getDetailUser(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Edit3Icon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'birthDay')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.birthDay))+" ")]):(props.column.field === 'dateTraining')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.dateTraining))+" ")]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('modal-add',{attrs:{"data":_vm.detailUser},on:{"update":_vm.updateUser}}),_c('confirm-modal',{attrs:{"id":"confirm-modal","title":'',"content":"Bạn có chắc chắn muốn xóa người lao động không ?"},on:{"accept":_vm.acceptDelete}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }