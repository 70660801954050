<template>
  <div>
    <div class="page-container mb-2">
      <h4 class="mb-2">
        Bộ lọc
      </h4>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Năm"
            label-for="year"
          >
            <v-select
              v-model="queryParams.year"
              :options="dataFilterYear || []"
              placeholder="Năm"
              @input="getListUser"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :contentBtnAdd="'Thêm người lao động'"
        :showBtnMultiDelete="showBtnMultiDelete"
        :arrayExcel="arrayExcel"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel($event)"
        @clickDelete="deleteItems"
        @clickAdd="addUser"
        @search="search($event)"
      />
      <vue-good-table
        ref="user-table"
        class="mh-60"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <span class="text-nowrap">
            {{ props.column.label }}
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span
              class="ml-2"
              @click="getDetailUser(props.row.id)"
            >
              <feather-icon
                icon="Edit3Icon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
          <span v-else-if="props.column.field === 'birthDay'">
            {{ props.row.birthDay | formatDate }}
          </span>
          <span v-else-if="props.column.field === 'dateTraining'">
            {{ props.row.dateTraining | formatDate }}
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        @pageClick="handlePageClick"
      />
      <modal-add
        :data="detailUser"
        @update="updateUser"
      />
      <confirm-modal
        id="confirm-modal"
        :title="''"
        content="Bạn có chắc chắn muốn xóa người lao động không ?"
        @accept="acceptDelete"
      />
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAdd from './components/ModalAdd.vue'
import store from '../store'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ConfirmModal,
    VBTooltip,
    ButtonAllHeader,
    BFormGroup,
    VSelect,
    BRow,
    BCol,
    ModalAdd,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataFilterYear: [],
      queryParams: {
        year: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'Ngày đào tạo',
          field: 'dateTraining',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      showBtnMultiDelete: false,
      deletedIds: [],
      totalRecord: 0,
      detailUser: undefined,
      arrayExcel: [
        'name',
        'birthDay',
        'gender',
        'address',
        'phoneNumber',
        'dateTraining',
        'isTrainingSkill',
        'isTrainingJob',
        'isRecruit',
      ],
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  created() {
    this.getListUser()
    this.getYear()
    this.dataFilterYear = this.yearList
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    ...mapActions('training', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),
    async getListUser() {
      this.$showLoading()
      const { data } = await store.getListUser(this.queryParams)
      if (data) {
        this.dataList = data.pageLists
        this.totalRecord = data.totalRecord
      }
      this.$hideLoading()
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListUser()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListUser()
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    deleteItems() {
      this.deletedIds = this.$refs['user-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    async acceptDelete() {
      const res = await store.deleteUser(this.deletedIds)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa người lao động thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListUser()
      }
    },
    async updateUser(info) {
      let variant = ''
      let message = ''
      if (!info.id) {
        const res = await store.addUser(info)
        if (res.success) {
          variant = 'success'
          message = 'Thêm người lao động thành công'
        } else {
          variant = 'danger'
          message = 'Thêm người lao động thất bại'
        }
      } else {
        const res = await store.editUser(info)
        if (res.success) {
          variant = 'success'
          message = 'Chỉnh sửa người lao động thành công'
        } else {
          variant = 'danger'
          message = 'Chỉnh sửa người lao động thất bại'
        }
      }
      this.getListUser()
      this.$root.$bvToast.toast(message, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
    },
    addUser() {
      this.detailUser = undefined
      this.$bvModal.show('modal-add-employee')
    },
    async getDetailUser(id) {
      const res = await store.getDetailUser(id)
      this.detailUser = res
      this.$bvModal.show('modal-add-employee')
    }, // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const datafile = dataInput.map(element => ({
        ...element,
        dateTraining: Date.parse(element.dateTraining) ? `${new Date(element.dateTraining).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
        birthDay: Date.parse(element.birthDay) ? `${new Date(element.birthDay).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
      }))
      const model = {
        listExcel: datafile,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'training-employment-import' })
    },
  },
}
</script>
